body {
	padding: 8px;
}
#root .no-margin {
	margin: 0;
}
#root .MuiSlider-thumb {
	width: 14px;
	height: 14px;
}
#root .MuiSlider-track {
	height: 1px;
}
#root .MuiSlider-rail {
	color: #d9d9d9;
}
#root .MuiFormLabel-root {
	width: 100%;
	margin-bottom: 6px;
	margin: 4px 0 6px 6px;
	transform: none;
	font-size: 12px;
}
#root .MuiBox-root > .MuiFormLabel-root {
	max-width: 42px;
}
#root .MuiSelect-select {
	padding: 24px 14px 18px;
}
#root .grid-box {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 20px;
	grid-row-gap: 20px; 
}
#root .deleteButton:hover {
	color: rgb(209, 49, 53);
  background-color: rgb(209, 49, 53, .2);
}
#root .cards-box {
	display: flex;
	flex-direction: column;
	width: 100%;
}

@media (min-width: 1024px) {
	body {
		padding: 20px;
	}
	#root .cards-box {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 20px; 
	}
}